<template>
    <div class="chart-area">
        <div class="left-list">
            <div class="date-range">
                <el-date-picker
                    @change="dateChange"
                    v-model="group.daterange"
                    type="daterange"
                    class="my-date-range"
                    size="small"
                    value-format="timestamp"
                    :picker-options="pickerOptions"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
            </div>
            <div class="staff-list">
                <el-select size="small" class="my-el-select" v-model="group.currentStaff" placeholder="请选择对应人员">
                    <el-option
                        v-for="item in group.customerStaff"
                        :key="item.user_id"
                        :label="item.staff_name"
                        :value="item.user_id">
                    </el-option>
                </el-select>
            </div>
            <div class="type-select">
                <el-radio v-model="group.type" label="single">单独聊天</el-radio>
                <!--                <el-radio v-model="group.type" label="offline">离线群</el-radio>-->
                <el-radio v-model="group.type" label="group">群聊天</el-radio>
            </div>
            <div class="group-list">
                <div v-for="(item,index) in group.data" :key="item.id" @click="getMessage(item)">
                    <chart-user @click="chartClick" :index="index" :current="group.currentStaff" :chart="item"
                                :active="item.id===group.current.id"></chart-user>
                </div>
            </div>
        </div>
        <div class="data-area">
            <div class="top-follow">
                <span class="title">其它客服:</span>
                <el-radio @change="elseChange" v-for="item in group.elseStaff" :key="item.userid"
                          v-model="group.currentElseStaff" :label="item.userid">
                    {{ group.staffMapping[item.userid] || item.userid }}
                </el-radio>
            </div>
            <div ref="chart" class="chart-container">
                <div class="content"
                     :class="{innerUser:item.from===group.currentStaff||item.from===group.currentElseStaff}"
                     v-for="item in group.message" :key="item.id">
                    <div class="time">
                        <format-time format="Y-m-d H:i:s" :time="item.time"></format-time>
                    </div>
                    <div class="user">
                        <img class="user-avatar" :src="group.avatar[item.from]" v-if="group.avatar[item.from]" alt="">
                        <span class="username">{{ group.staffMapping[item.from] || item.from }}</span>
                    </div>
                    <div class="text-area">
                        <span class="text" v-if="item.type==='text'">
                            {{ item.content }}
                        </span>
                        <wx-image :data="item" v-else-if="item.type==='image'"></wx-image>
                        <wx-file :my-class="[item.from===group.currentStaff||item.from===group.currentElseStaff?'inner':'customer']" :data="item"
                                 v-else-if="item.type==='file'"></wx-file>
                        <wx-video :my-class="[item.from===group.currentStaff||item.from===group.currentElseStaff?'inner':'customer']" :data="item"
                                  v-else-if="item.type==='video'"></wx-video>
                        <wx-link :my-class="[item.from===group.currentStaff||item.from===group.currentElseStaff?'inner':'customer']" :data="item"
                                 v-else-if="item.type==='link'"></wx-link>
                        <wx-weapp :my-class="[item.from===group.currentStaff||item.from===group.currentElseStaff?'inner':'customer']" :data="item"
                                  v-else-if="item.type==='weapp'"></wx-weapp>
                        <wx-mixed :my-class="[item.from===group.currentStaff||item.from===group.currentElseStaff?'inner':'customer']" :data="item"
                                  v-else-if="item.type==='mixed'"></wx-mixed>
                        <wx-audio :my-class="[item.from===group.currentStaff||item.from===group.currentElseStaff?'inner':'customer']" :data="item"
                                  v-else-if="item.type==='voice'"></wx-audio>
                        <span v-else-if="item.type==='revoke'">撤回了一条消息</span>
                        <img style="max-height: 110px;" v-else-if="item.type==='emotion'"
                             :src="`http://api.bigemap.com/work/chart/file?size=1&md5=${item.content.md5sum}&type=image&id=${item.content.sdkfileid}`"
                             alt="">
                        <span v-else>

                        {{ item.type }}
                    </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import FormatTime from "@/components/common/format-time";
import ChartUser from "@/components/wxaudit/chart-user";
import WxImage from "@/components/wxaudit/wx-image";
import WxFile from "@/components/wxaudit/wx-file";
import WxVideo from "@/components/wxaudit/wx-video";
import WxLink from "@/components/wxaudit/wx-link";
import WxWeapp from "@/components/wxaudit/wx-weapp";
import WxMixed from "@/components/wxaudit/wx-mixed";
import WxAudio from "@/components/wxaudit/wx-audio";

export default {
    name: "manage",
    components: {
        FormatTime, ChartUser, WxImage, WxFile, WxVideo, WxLink, WxWeapp, WxMixed, WxAudio
    },
    data: function () {
        return {
            baseUrl: '/work/chart/group',
            baseSingle: '/work/chart/single',
            baseMessageUrl: '/work/chart/message',
            baseStaffUrl: '/work/staff/index',
            group: {
                currentStaff: '',
                current: {},
                staff: [],
                data: [],
                message: [],
                staffMapping: {},
                customerStaff: [],
                elseStaff: [],
                currentElseStaff: '',
                avatar: {},
                daterange: [new Date().getTime(), new Date().getTime()],
                type: 'single',
            },
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
        }
    },
    mounted() {
        this.getStaff();
    },
    watch: {
        'group.currentStaff': function () {
            this.group.message = [];
            this.group.elseStaff.splice(0, 1000);
            this.group.currentElseStaff = this.group.currentStaff;
            this.group.data = [];
            this.getGroup();
        },
        'group.type': function () {
            this.group.data = [];
            this.group.message = [];
            this.getGroup();
        },
    },
    computed: {},
    methods: {
        elseChange(val) {
            this.getChartData({
                user_id:val,
                customer_id: this.group.current.customer_id
            });
            // this.getMessage()
        },
        getGroup() {
            let url = this.group.type === 'single' ? this.baseSingle : this.baseUrl;
            this.$axios(url, {
                timeout:20000,
                params: {
                    pageSize: 10000,
                    search: {
                        user_id: this.group.currentStaff,
                        range: this.group.daterange,
                    }
                }
            }).then(message => {
                let data = [];
                message.data.map(v => {
                    v.type = this.group.type;
                    data.push(v);
                });
                this.group.data = data.sort((a, b) => {
                    return a.update_time > b.update_time ? -1 : 1;
                });
            });
        },
        getMessage(item) {
            this.group.elseStaff.splice(0, 1000);
            this.group.currentElseStaff = this.group.currentStaff;
            if (item.type !== 'group' && item.else && item.else.follow_user && item.else.follow_user.length > 1) {
                this.group.elseStaff.push(...item.else.follow_user);
            }
            this.group.current = item;
            this.getChartData(item);
        },
        getChartData(item) {
            let db = item.user_id + '@' + item.customer_id;
            if (item.type === 'group') {
                db = item.group_id;
            }
            this.$axios.get(this.baseMessageUrl, {
                params: {
                    db,
                    customer_id: item.customer_id,
                }
            }).then(data => {
                this.group.message = data.data.data.reverse().map(v => {
                    try {
                        if (v.type !== 'text') {
                            v.content = JSON.parse(v.content);
                        }
                    } catch (e) {

                    }
                    return v;
                });
                setTimeout(() => {
                    let dom = this.$refs['chart'];
                    dom.scrollTop = dom.scrollHeight;
                }, 55);
            });
        },
        chartClick(data) {
            this.group.staffMapping[data.chart.customer_id] = data.title;
            this.group.avatar[data.chart.customer_id] = data.avatar;
        },
        getStaff() {
            this.$axios(this.baseStaffUrl, {
                params: {
                    pageSize: 1000,
                }
            }).then(data => {
                this.group.staff = data.data.data;
                data.data.data.map(v => {
                    if (v.is_record) {
                        this.group.customerStaff.push(v);
                    }
                    this.group.staffMapping[v.user_id] = v.staff_name;
                });
            });
        },
        dateChange() {
            if (!this.group.currentStaff) return;
            this.getGroup();
        },
        downloadAttach(item) {
            console.log(item);
            // let content=JSON.parse(item.content);
            // console.log(content);
        },
        scroll: function () {
            let dom = this.$refs['scroll-message'];
            if (dom.scrollHeight < (dom.clientHeight + dom.scrollTop + 20)) {
                if (this.$store.state.loading || !this.formData.hasNext) return;
                this.getData();
            }
        }
    }
}
</script>

<style scoped lang="less">
.chart-area {
    height: calc(100vh - 100px);
    background-color: #ffffff;
    display: flex;

    .left-list {
        .staff-list {
            padding: 10px;
            text-align: center;

            .my-el-select {
                width: 100%;
            }
        }

        .type-select {
            text-align: center;
            padding-bottom: 10px;
        }

        .date-range {
            padding: 10px 10px 0;

            .my-date-range.el-date-editor--daterange.el-input__inner {
                width: 290px;
            }

            .my-date-range {
                ::v-deep {
                    .el-range-separator {
                        padding: 0;
                    }
                }

                &.el-date-editor {

                }
            }
        }

        .group-list {
            //margin-top: 20px;
            height: calc(100% - 120px);
            overflow: auto;
            width: 300px;
            flex-shrink: 0;
        }
    }

    .data-area {
        flex: 1;
        padding: 15px;
        margin: 10px;
        border: 1px solid #f1f1f1;
        overflow: hidden;
        height: 100%;

        .top-follow {
            //padding-bottom: 10px;
            min-height: 30px;

            .title {
                padding-right: 10px;
            }
        }

        .chart-container {
            border: 1px solid #ddd;
            border-radius: 10px;
            overflow: auto;
            padding: 5px 10px;
            height: calc(100% - 20px);
        }

        .content {
            border-bottom: 1px solid #eee;

            &.innerUser {
                text-align: right;

                .user {
                    flex-flow: row-reverse;
                }
            }

            > div {
                padding: 2px 0;
            }

            .time {
                font-size: 14px;
                color: #777;
            }

            .user {
                font-size: 14px;
                color: #777;
                display: flex;
                align-items: center;

                .username {
                    padding: 0 10px;
                }

                .user-avatar {
                    width: 40px;
                    height: 40px;
                    padding: 2px;
                    border: 1px solid #ccc;
                    display: inline-block;
                    border-radius: 5px;
                }
            }

            .text-area {
                .text {
                    display: inline-block;
                    word-break: break-all;
                    padding: 5px;
                    border-radius: 5px;
                    color: #fff;
                    background-color: rgba(15, 120, 203, 1);
                    min-width: 10px;
                    max-width: 95%;
                    line-height: 24px;
                    position: relative;
                }
            }

            margin-bottom: 15px;
        }
    }
}
</style>
