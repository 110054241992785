<template>
    <div @click="click" :class="{active}" class="chart-item">
        <div class="last-time">
            <format-wx-time :time="chart.update_time" format="Y-m-d H:i:s"></format-wx-time>
        </div>
        <div v-if="chart.type==='group'" class="title">
            <div class="avatar">
                <img :src="avatar" class="avatar" alt="">
            </div>
            <div class="content">
                {{ chart.title || '群聊' + index }}
            </div>
        </div>
        <div v-else class="title">
            <div class="avatar">
                <img :src="avatar" class="avatar" alt="">
            </div>
            <div class="content">
                <div>{{ title }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import FormatWxTime from "@/components/common/format-wx-time";
export default {
    name: "chart-user",
    props:{
        chart:{

        },
        active:{

        },
        index:{

        },
        current:{

        }
    },
    computed:{
        title:function (){
            let baseName=this.chart.else&&this.chart.else.external_contact&&this.chart.else.external_contact.name;
            let remark='';
            this.chart.else&&this.chart.else.follow_user&&this.chart.else.follow_user.map(v=>{
                if (v.userid===this.current){
                    remark=v.remark;
                }
            });
            return remark||baseName;
        },
        avatar:function (){
            let img=this.chart.else&&this.chart.else.external_contact&&this.chart.else.external_contact.avatar;
            return img||'/static/manage/images/avatar/base.png';
        }
    },
    components:{
        FormatWxTime
    },
    methods:{
        click(){
            this.$emit('click',{title:this.title,avatar:this.avatar,chart:this.chart});
        }
    }
}
</script>

<style lang="less" scoped>
.chart-item {
    border-bottom: 1px solid #ccc;
    padding: 5px;
    position: relative;

    .last-time {
        font-size: 12px;
        color: #bbb;
        top: 5px;
        right: 2px;
        position: absolute;
    }

    cursor: pointer;
    .title{
        display: flex;
        .avatar{
            width: 50px;
            flex-shrink: 0;
            img{
                display: inline-block;
                width: 50px;
                height: 50px;
                padding: 2px;
                border:1px solid #ccc;
                border-radius: 5px;
            }
        }
        .content{
            display: flex;
            align-items: center;
            padding-left: 15px;
            justify-content: center;
        }
    }
    &.active {
        background-color: #66a6ff;
        color: #fff;

        .last-time {
            color: #eee;
        }
    }
}
</style>
