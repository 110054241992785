<template>
    <span>
        {{timeStr}}
    </span>
</template>

<script>
    export default {
        props:{
            time:{
                require:true,
                type:Number,
            },
            format:{
                default:function () {
                    return 'Y-m-d H:i:s';
                }
            }
        },
        name: "format-wx-time",
        computed:{
            timeStr:function () {
                try {
                    let format=this.format;
                    if (!this.time) return  '';
                    let t=this.time.toString().length===10?this.time*1000:this.time;
                    t=parseInt(t);
                    let current=new Date().getTime();
                    switch (true){
                        case current-t<60*60*1000&& new Date().getDate()===new Date(t).getDate():
                            //时间在1小时之内，同时没有跨天
                            format=Math.ceil((current-t)/1000/60)+'分钟前';
                            break;
                        case current-t<12*60*60*1000&& new Date().getDate()===new Date(t).getDate():
                            //时间在12小时之内，同时没有跨天
                            format='H:i:s';
                            break;
                        case current-t<7*24*60*60*1000:
                            //时间在7天之内
                            format='m-d H:i';
                            break;
                    }
                    t=new Date(t);
                    let f={
                        Y:t.getFullYear(),
                        m:(t.getMonth()+1).toString().padStart(2,'0'),
                        d:t.getDate().toString().padStart(2,'0'),
                        H:t.getHours().toString().padStart(2,'0'),
                        i:t.getMinutes().toString().padStart(2,'0'),
                        s:t.getSeconds().toString().padStart(2,'0')
                    }
                    let result=format;
                    for(let k in f){
                        result=result.replace(k,f[k]);
                    }
                    return result;
                }catch (e) {
                    // console.log(e);
                    return  '';
                }

            }
        }
    }
</script>

<style lang="less" scoped>

</style>
