<template>
    <span class="image" @click="prevImage()">
                        <img
                            :src="src"
                            alt="">
                    </span>
</template>

<script>
export default {
    name: "wx-image",
    props: {
        data: {}
    },
    computed: {
        src: function () {
            return `http://api.bigemap.com/work/chart/file?size=${this.data.content.filesize}&md5=${this.data.content.md5sum}&type=image&id=${this.data.content.sdkfileid}`
        }
    },
    methods: {
        prevImage() {
            this.$hevueImgPreview(this.src);
        }
    }
}
</script>

<style scoped lang="less">
.image {
    img{
        cursor: pointer;
        max-height: 150px;
        max-width: 70%;
    }
}
</style>
