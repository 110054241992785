<template>
    <div class="file-content" :class="[myClass]">
        <div  class="file" @click="download()">
        <div class="left-part">
            <div>
                {{ data.content.filename }}
            </div>
            <div class="bottom-text">
                {{ size }}
            </div>
        </div>
            <span class="image">
            <img src="/static/manage/images/chart/file.png?id11s" alt="">
        </span>
        </div>
    </div>
</template>

<script>
export default {
    name: "wx-file",
    props: {
        data: {},
        myClass:{
            default:''
        },
    },
    computed: {
        src: function () {
            return '';
        },
        size: function () {
            return this.tools.formatFileSize(this.data.content.filesize);
        }
    },
    methods: {
        download() {
            console.log(this.data);
        }
    }
}
</script>

<style scoped lang="less">
.file-content{
    position: relative;
    height: 60px;
    &.inner{
        .file{
            right: 0;
        }
    }
    &.customer{
        .file{
            left: 0;
        }
    }
    .file {
        display: flex;
        position: absolute;

        background-color: #2e5676;
        border-radius: 5px;
        color: white;
        cursor: pointer;
        height: 50px;
        align-items: center;
        justify-content: space-between;
        //width: 260px;

        .left-part {
            display: flex;
            padding-left: 10px;
            flex-direction: column;
            align-items: flex-start;
            height: 100%;
            justify-content: space-around;
            .bottom-text{
                font-size: 14px;
                color: #ccc;
            }
        }

        .image {
            width: 70px;
            flex-shrink: 0;
            height: 70%;

            img {
                max-height: 100%;
            }
        }
    }
}

</style>
